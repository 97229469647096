import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { Image } from "../../subcomponents/Elements";
import { useLocation } from "react-router-dom";
import {
  amplitudePageEvent,
  ampRabbitMQPageEvent,
  ampRabbitMQSuccessPageEvent,
  getParam,
} from "../../../utils";
import { logData } from "../../../utils/services";


export function Tvod({ plansData }) {
  const location = useLocation().search;
  const lang = getParam(location, "lang");
  const hostname = window.location.host;
  const pathname = useLocation().pathname;
  const couponcode = getParam(location, "couponcode");
  var callback_url = getParam(location, "callback_url");
  // const identity = getParam(location, "identity");
  var make_watch_url = "";
  if (plansData?.formData?.orderData?.payment_name.includes("VOD")) {
    // console.log(plansData.formData.orderData.payment_name, plansData.formData.orderData.payment_name.includes('VOD') );
    const contentName =
      plansData?.formData?.orderData?.content_details?.plan_name?.replace(
        /\s+/g,
        "-"
      );
    const content_id = plansData?.formData?.orderData?.content_id;
    var watch_url =
      `${process.env.REACT_APP_HUNGAMA_WEB_URL}/movie/` +
      contentName +
      "/" +
      content_id;
    console.log("watch_url=" + watch_url);
    make_watch_url = watch_url;
  } else {
    make_watch_url = callback_url && window.atob(callback_url);
  }
  // var ua = navigator.userAgent.toLowerCase();
  // var isAndroid =ua.indexOf("android") > -1
  // var ios = /iphone|ipad/.test(ua);
  const TRACKING_ID = `${process.env.REACT_APP_GA_CODE}`; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
  //console.log(plansData.data.plans[1].is_recommended);
  const trackGaEvent = (mname) => {
    //console.log('here:'+mname);
    gaEventTracker("watch_now", mname);
  };
  const useAnalyticsEventTracker = (category = "Payment Init") => {
    const eventTracker = (action = "action init", label = "label init") => {
      ReactGA.event({ category, action, label });
    };
    return eventTracker;
  };
  const gaEventTracker = useAnalyticsEventTracker("Post Payment Screens");

  //   if(plansData.formData.orderData.payment_name=="PTVOD150" && plansData.formData.orderData.payment_id==9){
  //     callback_url = make_watch_url;
  //   }else{
  //     callback_url = callback_url && window.atob(callback_url);

  //   }

  //amplitude
  useEffect(() => {
    const {
      plan_name,
      plan_valid,
      plan_amount,
      order_id,
      identity,
      plan_type,
    } = plansData?.formData?.orderData;
    let source = "";
    if (plan_type === "live_concert") {
      source = "PG Page_Live Event";
    } else {
      source = "PG Page_TVOD";
    }
    /*
    amplitudePageEvent(identity, {
      "Page Type": "PG Page_Success",
      Source: source,
      "Plan Name": plan_name,
      "Plan Validity": plan_valid,
      "Plan Value": plan_amount,
      order_id: order_id,
      "Page Language": lang ? lang : "en",
    });
    */
    /* amplitude with RabbitMQ */
    ampRabbitMQSuccessPageEvent({
      identity: identity,
      "Page Type": "PG Page_Success",
      Source: source,
      "Plan Name": plan_name,
      "Plan Validity": plan_valid,
      "Plan Value": plan_amount,
      order_id: order_id,
      "Page Language": lang ? lang : "en",
      product_id: plansData?.formData?.orderData?.product_id,
      platform_id: plansData?.formData?.orderData?.platform_id,
      device : "desktop"
    });

    /* Frontend Logs */
    const lgData = {
      url: hostname + pathname,
      params: location,
      couponcode: couponcode,
      order_data: plansData?.formData?.orderData,
      log_time: new Date().toLocaleString().replace(",", ""),
      log_type: "FRONTEND_PAYMENT_RESPONSE | Success",
    };
    logData({ logData: JSON.stringify(lgData) }).then(() => {});
  }, [plansData, lang, couponcode, hostname, location, pathname]);

  return (
    <>
      <div className="pdlr-40 mb-20 d-flex">
        <div className="left-box">
          <div className="ticket-confirm-box overflow-hidden">
            <div className="ticket-confirm-box-detail">
              <div
                className="ticket-bg"
                style={{
                  backgroundImage: `url(${process.env.REACT_APP_ASSETS_URL}/web/images/ticket-cover-image.png)`,
                }}
              ></div>
              <div className="w-70 fleft" style={{ paddingRight: "30px" }}>
                <h3>
                  {plansData?.formData?.orderData?.content_details?.plan_name}
                </h3>
                <p>
                  {plansData?.formData?.orderData?.content_details?.plan_des}
                </p>
                <img alt="" src="line-sep-1.svg" className="line-sep-1" />
                <div className="dflex">
                  <a
                    href={
                      make_watch_url
                        ? make_watch_url
                        : "http://hungama.com/payment/success/"
                    }
                    onClick={(e) =>
                      trackGaEvent(
                        plansData?.formData?.orderData?.content_details
                          ?.plan_name
                      )
                    }
                    className="button-blue d-block"
                  >
                    <i className="icon-play button-icon"></i>
                    {plansData?.language?.pay_web_tvod_text1}
                  </a>
                </div>
              </div>
              <div className="w-30 fleft position-relative">
                <img
                  src={
                    plansData?.formData?.orderData?.content_details
                      ?.plan_image_desktop
                  }
                  alt=""
                />
                <Image src="paid.png" alt="" className="paid-img" />
              </div>
            </div>
            <div className="ticket-footer">
              <Image src="ticket-bottom.svg" alt="" />
            </div>
          </div>
        </div>
        <div className="right-box">
          <div className="box-content box-content-reset blur-box">
            <div className="box-content-inner">
              <p className="p-t1"> {plansData?.language?.pay_web_tvod_text2}</p>
              <div className="order-detail" id="rowp-4">
                <div className="order-first-col">
                  {plansData?.language?.pay_web_tvod_text3}:
                </div>
                <div className="order-last-col">
                  {plansData?.formData?.orderData?.order_id}
                </div>
              </div>
              <div className="order-detail">
                <div className="order-first-col">
                  {plansData?.language?.pay_web_tvod_text4}:
                </div>
                <div className="order-last-col">
                  {plansData?.formData?.orderData?.plan_duration}{" "}
                  {plansData?.language?.pay_web_tvod_text5}
                </div>
              </div>
              {plansData?.formData?.orderData?.payment_id !== "14" && (
                <div className="order-detail">
                  <div className="order-first-col">
                    {plansData?.language?.pay_web_tvod_text6}:
                  </div>
                  <div className="order-last-col">
                    <span className="text-white">
                      {plansData?.formData?.orderData?.plan_currency_symbol}
                      {plansData?.formData?.orderData?.txn_amount}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      
      
    </>
  );
}
