import React from "react";
import ReactGA from "react-ga";

import { useHistory } from "react-router-dom";
import { amplitudeBtnEvent, ampRabbitMQBtnEvent } from "../../utils";

const Netbanking = ({
  identity,
  textData,
  urlparams,
  netBankingPayments,
  coupon_code,
  product_id,
  platform_id,
  lang,
}) => {
  let history = useHistory();
  const TRACKING_ID = `${process.env.REACT_APP_GA_CODE_PWA}`; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
  const useAnalyticsEventTracker = (category = "Payment Init") => {
    const eventTracker = (action = "action init", label = "label init") => {
      ReactGA.event({ category, action, label });
    };
    return eventTracker;
  };

  const netBankingMore = (event, data) => {
    event.preventDefault();
    history.push(
      `/netbanking/${lang}?` + urlparams + `&coupon_code=${coupon_code}`,
      {
        nb: data,
      }
    );
    // amplitudeBtnEvent(identity, "Btn_OtherBanks_NB", product_id, platform_id);
    ampRabbitMQBtnEvent(
      { identity: identity, product_id: product_id, platform_id: platform_id },
      "Btn_OtherBanks_NB"
    );
  };
  const gaEventTracker = useAnalyticsEventTracker("Net Banking");

  const handleNetbanking = (e, data) => {
    const bank_code = e.target.getAttribute("bank_code");
    // amplitudeBtnEvent(identity, "Btn_Submit_NB", product_id, platform_id);
    ampRabbitMQBtnEvent(
      {
        identity: identity,
        product_id: product_id,
        platform_id: platform_id,
      },
      "Btn_Submit_NB"
    );
    var item_value = sessionStorage.getItem("plan_name_val");
    gaEventTracker(data.bank_display_name, item_value);
    sessionStorage.removeItem("plan_name_val");

    //return;
    const url = `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?payment_id=${data?.payment_id}&plan_details_id=${data?.plan_details_id}&pg=NB&bankcode=${bank_code}&type=charge&${urlparams}&coupon_code=${coupon_code}`;
    window.location.href = url;
  };

  return (
    <>
      {netBankingPayments && netBankingPayments?.length > 0 ? (
        <div className="pay-mode-box">
          <p className="p-t1">{textData?.pay_netbanking_label}</p>
          <div className="banking-name-box flex1">
            {netBankingPayments
              .sort(
                (a, b) =>
                  parseFloat(a?.display_order) - parseFloat(b?.display_order)
              )
              .map((data, i) => (
                <div
                  className="bank-box"
                  key={i}
                  bank_code={data?.bank_code}
                  plan_details_id={data?.plan_details_id}
                  payment_id={data?.payment_id}
                  onClick={(e) => handleNetbanking(e, data)}
                >
                  <div className="pointer">
                    <img
                      bank_code={data?.bank_code}
                      src={data?.payment_logo}
                      alt={data?.bank_display_name}
                      className="bank-icon-img"
                    />
                  </div>
                  <p className="bank-name" bank_code={data?.bank_code}>
                    <a
                      href="!#"
                      className="pointer"
                      bank_code={data?.bank_code}
                    >
                      {data?.bank_display_name}
                    </a>
                  </p>
                </div>
              ))}
            <div
              className="bank-box"
              onClick={(e) => netBankingMore(e, netBankingPayments)}
            >
              <div className="pointer">
                <img
                  src={
                    process.env.REACT_APP_ASSETS_URL +
                    "/mobile/images/more-bank.svg"
                  }
                  alt="more banks"
                  className="bank-icon-img"
                />
              </div>

              <p className="bank-name">
                <a href="!#" className="pointer">
                  {textData?.pay_more_text}
                </a>
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};
export default Netbanking;
