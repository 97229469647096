import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Tvodsuccess from "../transactionsucess/tvodsuccess";
import Subscriptionsuccess from "../transactionsucess/subscriptionsuccess";
import { language } from "../../utils/services";
import ReactGA from "react-ga";

export function Paymentsuccess() {
  //  GA code
  const TRACKING_ID = `${process.env.REACT_APP_GA_CODE_PWA}`; // OUR_TRACKING_ID
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(window.location.pathname);
  }, [TRACKING_ID]);

  const [isLoading, setLoading] = useState(true);
  let location = useLocation().search;
  const order_id = new URLSearchParams(location).get("order_id");
  const product_id = new URLSearchParams(location).get("product_id");
  const lang = new URLSearchParams(location).get("lang");
  const platform_id = new URLSearchParams(location).get("platform_id");
  const [orderData, setOrderData] = useState([]);
  const [planType, setPlanType] = useState("");
  const [txtData, setTextData] = useState({});

  useEffect(() => {
    if (isLoading) {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "api-key": process.env.REACT_APP_API_KEY,
      };
      const body = {
        order_id: order_id,
      };

      axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/v1/user/check_order`,
        headers: headers,
        data: body,
      })
        .then((res) => {
          if (res?.data) {
            setOrderData(res?.data?.data?.order_data);
            sendEvent(res.data.data.order_data)
            setPlanType(res?.data?.data?.order_data?.plan_type);
            setLoading(false);
          }
        })
        .catch((err) => {
          // console.log(err);
          setLoading(false);
        });
        const sendEvent = async (order) => {
          const url = 'https://graph.facebook.com/v20.0/646273460308855/events';
          const response = await axios.get('https://api.ipify.org?format=json');
          const data = [
            {
              event_name: "Purchase_web",
              event_time: Math.floor(Date.now() / 1000),
              user_data: {
                em: [
                  "309a0a5c3e211326ae75ca18196d301a9bdbd1a882a4d2569511033da23f0abd"
                ],
                ph: [
                  "254aa248acb47dd654ca3ea53f48c2c26d641d23d7e2e93a1ec56258df7674c4",
                  "6f4fcb9deaeadc8f9746ae76d97ce1239e98b404efe5da3ee0b7149740f89ad6"
                ],
                client_ip_address: response.data.ip,
                client_user_agent: navigator.userAgent,
                fbc: "fb.1.1554763741205.AbCdEfGhIjKlMnOpQrStUvWxYz1234567890",
                fbp: "fb.1.1558571054389.1098115397"
              },
              custom_data: {
                order_data: order,
                value:order.txn_amount,
                currency:order.plan_currency
              },
              event_source_url: "https://payments.hungama.com",
              action_source: "website"
            }
          ];
      
          try {
            const res = await axios.post(url, {
              data: JSON.stringify(data),
              access_token: 'EAAUWW487JAABOzxXRMlbAaYjWaMDiDHC4wZATX0pwJZCZAOHu4LZAVx28Pq0fZBz8GZAXgvVSyZBainuVH8OwTqDW6QZBkv6jtlfIVMFedq7uEb88wu5cYn19GqN7ZBllZA4mF8mZBKm7x5mo8EjLVDOLQ9ZAD8fWonsm9a68ZB3nRZAkOozRwh5ZCSYQEFv3Mb6AlukuOlSQZDZD'
            }, {
              headers: {
                'Content-Type': 'application/json'
              }
            });
            console.log('success response',res.data);
            // setResponse(res.data);
          } catch (error) {
            console.error("Error posting event:", error);
          }
        };
      //language
      language(lang).then((res) => {
        //set Lang conditionally
        // setDefaultTextData(res?.default);
        setTextData(res?.secondLang ? res?.secondLang : res?.default);
      });
    }
  }, [isLoading, order_id, lang]);
  // console.log("order success::", orderData);
  console.log(planType);
  return (
    <>
      {isLoading ? (
        <div className="loading">Loading...</div>
      ) : (
        <>
          {planType?.toLowerCase() === "tvod" ||
          planType?.toLowerCase() === "cvod" ||
          planType?.toLowerCase() === "ptvod" ||
          planType?.toLowerCase() === "pvod" ||
          planType?.toLowerCase()?.substring(0, 4) === "cvod" ||
          planType?.toLowerCase()?.substring(0, 4) === "live_concert" ||
          planType?.toLowerCase()?.substring(0, 5) === "ptvod" ||
          planType?.toLowerCase()?.substring(0, 2).toLowerCase() === "le" ? (
            <Tvodsuccess
              order_id={order_id}
              planType={planType}
              orderData={orderData}
              platform_id={platform_id}
              product_id={product_id}
              txtData={txtData}
              lang={lang}
            />
          ) : (
            <Subscriptionsuccess
              order_id={order_id}
              orderData={orderData}
              platform_id={platform_id}
              product_id={product_id}
              txtData={txtData}
              lang={lang}
            />
          )}
        </>
      )}
    </>
  );
}
