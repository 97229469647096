import React from "react";

const Offers = ({ textData, urlparams, featured, coupon_code }) => {

    const handlePayoffer = (e) => {
        const payment_id = e.target.getAttribute('payment_id');
        const plan_details_id = e.target.getAttribute('plan_details_id');
        window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?payment_id=${payment_id}&plan_details_id=${plan_details_id}&${urlparams}&coupon_code=${coupon_code}`;
    }

    return (
        <>
            <h2 className="pay-title">{textData?.secondLang?.pay_payment_method_label ? textData?.secondLang?.pay_payment_method_label : textData?.default?.pay_payment_method_label}</h2>
            {
                featured?.offer_text &&
                <div className="pay-mode-box">
                    <p className="p-t1">{textData?.secondLang?.pay_offer_of_the_day_label ? textData?.secondLang?.pay_offer_of_the_day_label : textData?.default?.pay_offer_of_the_day_label}</p>
                    <div className="payment-box flex1" payment_id={featured.payment_id} plan_details_id={featured.plan_details_id} onClick={e => handlePayoffer(e)}>
                        <div className="pay-mode-icon-box" payment_id={featured.payment_id} plan_details_id={featured.plan_details_id}>
                            <img src={featured.payment_logo} payment_id={featured.payment_id} plan_details_id={featured.plan_details_id} alt="paytm" className="pay-mode-img" />
                        </div>
                        <div className="pay-moffer-box" payment_id={featured.payment_id} plan_details_id={featured.plan_details_id}>
                            <span className="paym-name" payment_id={featured.payment_id} plan_details_id={featured.plan_details_id}>{featured.payment_name}</span>
                            <span className="paym-discoountbox" payment_id={featured.payment_id} plan_details_id={featured.plan_details_id}>{featured.offer_text}</span>
                        </div>
                        <div className="pay-mnext-arrowbox" payment_id={featured.payment_id} plan_details_id={featured.plan_details_id}>
                            <div className="pay-mnext-arrow"><img src={process.env.REACT_APP_ASSETS_URL+"/mobile/images/next-arrow.svg"} payment_id={featured.payment_id} plan_details_id={featured.plan_details_id} alt="nextarrow" /></div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}
export default Offers;