//import React from "react";
import React, { useState } from "react";
import ReactGA from "react-ga";
import { Image } from "../../subcomponents/Elements";
import NumberFormat from "react-number-format";
import {
  amplitudeBtnEvent,
  ampRabbitMQBtnEvent,
  getParam,
} from "../../../utils";
import {
  checkCreditCardNumber,
  validateCardNumber,
} from "../../../utils/payments";

export default function Credit({
  urlparams,
  paymentsData,
  updateMultipleFormData,
  isFreeTrial,
}) {
  const [isAgree, setIsAgree] = useState(true);
  /*
    //control auto pay checkbox for users
    let isValidUser=false;
    const identity = getParam(urlparams, 'identity');
    let allowCheckConsent=[];
    */
  let isValidUser = false;
  let plan_id = getParam(urlparams, "plan_id");
  const planType = getParam(urlparams, "plan_type").toLocaleLowerCase();
  if (planType !== "event" && !/vod/.test(planType) && plan_id !== "10") {
    isValidUser = true;
  }

  const [isValidName, setValidName] = useState(false);
  const [isValidNameSp, setValidNameSp] = useState(false);
  const [isValidCardNumber, setValidCardNumber] = useState(false);
  const [isValidMonth, setValidMonth] = useState(false);
  const [isValidCvv, setValidCvv] = useState(false);
  const [freeTrialCCValid, setfreeTrialCCValid] = useState(false);
  const TRACKING_ID = `${process.env.REACT_APP_GA_CODE}`; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
  const useAnalyticsEventTracker = (category = "Payment Init") => {
    const eventTracker = (action = "action init", label = "label init") => {
      ReactGA.event({ category, action, label });
    };
    return eventTracker;
  };
  const gaEventTracker = useAnalyticsEventTracker("Credit Debit Card");

  const validateCardNumberLoc = async (e, isFreeTrial) => {
    //console.log('cc='+paymentsData.data.planInfo.plan_name);
    gaEventTracker("pay_now", paymentsData?.data?.planInfo?.plan_name);
    var nameRegex = /^[A-Za-z\s]+$/;
    setValidName(false);
    setValidNameSp(false);
    setValidCardNumber(false);

    if (document.getElementById("cardHolderName").value === "") {
      setValidName(true);
      e.preventDefault();
      return false;
    } else if (document.getElementById("cardHolderName").value !== "") {
      if (!nameRegex.test(document.getElementById("cardHolderName").value)) {
        setValidName(false);
        setValidNameSp(true);
        e.preventDefault();
        return false;
      }
    }
    if (document.getElementById("cardNumber").value === "") {
      setValidCardNumber(true);
      setValidName(false);
      e.preventDefault();
      return false;
    }

    if (
      validateCardNumber(paymentsData?.formData?.cardNumber.replace(/ /g, ""))
    ) {
      // console.log("Valid Card Number");
      setValidName(false);
      // amplitudeBtnEvent(paymentsData?.formData?.identity, "Btn_Submit_CCDC");
      ampRabbitMQBtnEvent(
        {
          identity: paymentsData?.formData?.identity,
          product_id: paymentsData?.formData?.product_id,
          platform_id: paymentsData?.formData?.platform_id,
        },
        "Btn_Submit_CCDC"
      );
    } else {
      setValidCardNumber(true);
      setValidName(false);
      e.preventDefault();
      return false;
    }

    var result = true;
    var errMsg = "";
    var expire = document.getElementById("expiryDate").value;
    if (!expire.match(/(0[1-9]|1[0-2])[/][0-9]{2}/)) {
      errMsg += paymentsData?.language?.expire_date_format_error;
      result = false;
    } else {
      // get current year and month
      var d = new Date();
      var currentYear = d.getFullYear();
      var currentMonth = d.getMonth() + 1;
      // get parts of the expiration date
      var parts = expire.split("/");
      var year = parseInt(parts[1], 10) + 2000;
      var month = parseInt(parts[0], 10);
      // compare the dates
      if (
        year < currentYear ||
        (year === currentYear && month < currentMonth)
      ) {
        errMsg += paymentsData?.language?.expiry_date_passed_error;
        result = false;
      }
    }
    console.log(errMsg);
    if (!result) {
      setValidMonth(true);
      e.preventDefault();
      return false;
    } else {
      setValidMonth(false);
    }
    console.log(result);
    if (document.getElementById("cvv").value === "") {
      setValidMonth(false);
      setValidCvv(true);
      e.preventDefault();
      return false;
    }

    //free trial
    // console.log(
    //   "freeTrialStatus",
    //   isFreeTrial,
    //   "paymentsData.formData.cardCategory",
    //   paymentsData.formData.cardCategory
    // );
    if (
      isFreeTrial?.status === false &&
      isFreeTrial?.plan_id === plan_id &&
      paymentsData.formData.cardCategory === "DC"
    ) {
      // console.log("wrong DC for freetrial");
      setValidCvv(false);
      setfreeTrialCCValid(true);
      e.preventDefault();
      return false;
    } else {
      setfreeTrialCCValid(false);
    }
  };
  const checkCreditCardNumberFn = (e) => {
    const creditCardNbr = e.target.value.replace(/ /g, "");
    updateMultipleFormData({ cardNumber: e.target.value });
    // console.log(creditCardNbr.length);
    if (creditCardNbr?.length !== 0) {
      checkCreditCardNumber(creditCardNbr).then((res) => {
        if (res?.data?.cardType) {
          updateMultipleFormData({
            cardType: res?.data?.cardType,
            cardCategory: res?.data?.cardCategory,
          });
        }
      });
    }
  };
  return (
    <div
      id="credit"
      className="tabcontent"
      style={{
        display:
          paymentsData?.formData?.tabToShow === "credit" ? "block" : "none",
      }}
    >
      {paymentsData?.data?.payments.map(
        (data, i) =>
          data?.payment_name === "Credit Card" && (
            <form
              onSubmit={(e) => validateCardNumberLoc(e, isFreeTrial)}
              method="POST"
              action={`${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?payment_id=${data?.payment_id}&plan_details_id=${data?.plan_details_id}&type=charge&${urlparams}&coupon_code=${paymentsData?.formData?.couponcode}`}
              key={i}
            >
              <input
                type="hidden"
                value={`${data?.payment_id}`}
                name="hiddenpayment_id"
                id="hiddenpayment_id"
              />
              <input
                type="hidden"
                value={`${data?.plan_details_id}`}
                name="hiddenplan_details_id"
                id="hiddenplan_details_id"
              />
              <div className="ccrow">
                <p className="cc-tittle">
                  {paymentsData?.language?.pay_cc_name_label}
                </p>
                <div className="input-box">
                  <input
                    type="text"
                    value={paymentsData?.formData?.cardHolderName}
                    name="cardHolderName"
                    maxLength="100"
                    id="cardHolderName"
                    onChange={(e) =>
                      updateMultipleFormData({ cardHolderName: e.target.value })
                    }
                    placeholder={
                      paymentsData?.language?.pay_cc_name_placeholder
                    }
                    className="pay-input"
                  />
                </div>
                {isValidName && (
                  <p style={{ color: "red" }}>
                    {
                      paymentsData?.language
                        ?.pay_cc_enter_card_holder_name_error
                    }
                  </p>
                )}
                {isValidNameSp && (
                  <p style={{ color: "red" }}>
                    {
                      paymentsData?.language
                        ?.pay_cc_enter_valid_card_holder_name_error
                    }
                  </p>
                )}
              </div>
              <div className="ccrow">
                <p className="cc-tittle">
                  {paymentsData?.language?.pay_cc_number_label}
                </p>
                <ul className="card-type-list">
                  {paymentsData.formData.cardType === "RUPAY" && (
                    <li>
                      <Image
                        src="rupay.svg"
                        style={{ width: "40px", height: "11px" }}
                      />
                    </li>
                  )}
                  {paymentsData.formData.cardType === "MAST" && (
                    <li>
                      <Image
                        src="mastercard.svg"
                        style={{ width: "25px", height: "16px" }}
                      />
                    </li>
                  )}
                  {paymentsData.formData.cardType === "VISA" && (
                    <li>
                      <Image
                        src="visa.svg"
                        style={{ width: "25px", height: "15px" }}
                      />
                    </li>
                  )}
                  {paymentsData.formData.cardType === "AMEX" && (
                    <li>
                      <Image
                        src="amex.svg"
                        style={{ width: "20px", height: "20px" }}
                      />
                    </li>
                  )}
                </ul>
                <div className="input-box">
                  <NumberFormat
                    className="pay-input"
                    value={paymentsData?.formData?.cardNumber}
                    name="cardNumber"
                    id="cardNumber"
                    onChange={(e) => checkCreditCardNumberFn(e)}
                    placeholder={"xxxx-xxxx-xxxx-xxxx"}
                    format="#### #### #### ####"
                    autoComplete="off"
                  />
                </div>
                {isValidCardNumber && (
                  <p style={{ color: "red" }}>
                    {
                      paymentsData?.language
                        ?.pay_cc_enter_valid_card_umber_error
                    }
                  </p>
                )}
              </div>
              <div className="ccrow">
                <div className="cc-left-box">
                  <p className="cc-tittle">
                    {paymentsData?.language?.pay_cc_cvv_expiry_label}
                  </p>
                  <div className="input-box">
                    <NumberFormat
                      value={paymentsData?.formData?.expiryDate}
                      name="expiryDate"
                      id="expiryDate"
                      onChange={(e) =>
                        updateMultipleFormData({ expiryDate: e.target.value })
                      }
                      placeholder={
                        paymentsData?.language?.pay_cc_cvv_expiry_placeholder
                      }
                      className="pay-input"
                      format="##/##"
                      mask={["M", "M", "Y", "Y"]}
                    />
                  </div>
                  {isValidMonth && (
                    <p style={{ color: "red" }}>
                      {paymentsData?.language?.pay_cc_valid_expiry_error}
                    </p>
                  )}
                </div>
                <div className="cc-right-box">
                  <p className="cc-tittle">
                    {paymentsData?.language?.pay_cc_cvv_label}
                  </p>
                  <div className="input-box">
                    {/* <input maxLength="4" type="password" value={paymentsData.formData.cvv} id="cvv" name="cvv" onChange={(e) => updateMultipleFormData({ "cvv": e.target.value })} placeholder="Enter Card CVV" className="pay-input" /> */}
                    <NumberFormat
                      maxLength="3"
                      type="password"
                      value={paymentsData?.formData?.cvv}
                      id="cvv"
                      name="cvv"
                      onChange={(e) =>
                        updateMultipleFormData({ cvv: e.target.value })
                      }
                      placeholder={
                        paymentsData?.language?.pay_cc_cvv_placeholder
                      }
                      className="pay-input"
                    ></NumberFormat>
                  </div>
                  {isValidCvv && (
                    <p style={{ color: "red" }}>
                      {
                        paymentsData?.language
                          ?.pay_please_enter_cvv_number_error
                      }
                    </p>
                  )}
                </div>
              </div>
              <input
                type="hidden"
                name="pg"
                value={paymentsData?.formData?.cardCategory}
              />
              {isValidUser === true ? (
                <div className="agree-row">
                  <label>
                    <input
                      onChange={(e) => {
                        if (!e.target.checked) {
                          setIsAgree(false);
                        } else {
                          setIsAgree(true);
                        }
                      }}
                      type="checkbox"
                      name="checkbox"
                      value="value"
                      checked={isAgree}
                    />
                    {paymentsData?.language?.pay_consent}
                  </label>
                  {isAgree === false && (
                    <p className="error-msg">
                      {paymentsData?.language?.pay_consent_error}
                    </p>
                  )}
                  {/*Free Trial*/}
                  {isFreeTrial?.status === false &&
                    isFreeTrial?.plan_id === plan_id &&
                    isFreeTrial?.freeTrialPaymentPageText?.map((data, i) => (
                      <div
                        style={{
                          marginTop: "14px",
                          marginLeft: "29px",
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                      >
                        <p key={i}>{data}</p>
                      </div>
                    ))}
                </div>
              ) : (
                ""
              )}

              <div className="ccrow rightalign">
                {freeTrialCCValid && (
                  <p style={{ color: "red" }}>
                    {paymentsData?.language?.pay_enter_only_credit_card}
                    {/* Please Enter Only Credit Card Details. */}
                  </p>
                )}

                <input
                  disabled={isAgree === false ? true : false}
                  type="submit"
                  value={paymentsData?.language?.pay_cc_pay_now}
                  className="payment-btn"
                />
              </div>
            </form>
          )
      )}
    </div>
  );
}
