import React, { useState, useEffect } from "react";
// import { ReactDOM, Link } from "react-dom";
import ReactGA from "react-ga";
import { useLocation, useHistory } from "react-router";

import { amplitudeBtnEvent, ampRabbitMQBtnEvent } from "../../utils";
import { language } from "../../utils/services";

import { netBankArray } from "../../../constants";

export const Netbanking = (props) => {
  let history = useHistory();
  //  console.log(planData);
  const TRACKING_ID = `${process.env.REACT_APP_GA_CODE_PWA}`; // OUR_TRACKING_ID
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(window.location.pathname);
  }, [TRACKING_ID]);
  const useAnalyticsEventTracker = (category = "Payment Init") => {
    const eventTracker = (action = "action init", label = "label init") => {
      ReactGA.event({ category, action, label });
    };
    return eventTracker;
  };
  const location = useLocation();
  const urlparams = location.search.replace("?", "");
  const lan = new URLSearchParams(location.search).get("lang");
  const coupon_code = new URLSearchParams(location.search).get("coupon_code");
  const product_id = new URLSearchParams(location.search).get("product_id");
  const platform_id = new URLSearchParams(location.search).get("platform_id");

  const goToPreviousPath = () => {
    history.push("/payment?" + urlparams);
  };

  // const [bankSelect, setBankSelect] = useState("AIRNB");
  const [textData, setTextData] = useState({});
  const [isLoading, setLoading] = useState(true);

  const nbPayments = location.state?.nb ? location.state?.nb : [];
  const identity = location.state?.identity ? location.state?.identity : "";
  const gaEventTracker = useAnalyticsEventTracker("Net Banking");
  const handleNetbanking = (e, data) => {
    // amplitudeBtnEvent(identity, "Btn_Submit_NB", product_id, platform_id);
    ampRabbitMQBtnEvent(
      {
        identity: identity,
        product_id: product_id,
        platform_id: platform_id,
      },
      "Btn_Submit_NB"
    );
    var item_value = sessionStorage.getItem("plan_name_val");
    gaEventTracker(data.bank_display_name, item_value);
    //        console.log(nbPayments);
    //  return;

    const bank_code = e?.target?.getAttribute("bank_code");
    // console.log(nbPayments[0].payment_id);
    // console.log(nbPayments[0].plan_details_id);
    const payment_id = nbPayments[0].payment_id;
    const plan_details_id = nbPayments[0].plan_details_id;
    const url = `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?payment_id=${payment_id}&plan_details_id=${plan_details_id}&pg=NB&bankcode=${bank_code}&type=charge&${urlparams}&coupon_code=${coupon_code}`;
    window.location.href = url;
  };

  // const handleForm = (e) => {
  //     const bank_code = e?.target?.value;
  //     console.log(bank_code);
  //     // setBankSelect(bank_code);
  // }

  // const nb = nbPayments.;
  //    const { data } = props..
  // const [netbankingPayments, setNetbankingPayments] = useState([]);
  // setNetbankingPayments(nbPayments);
  useEffect(() => {
    if (isLoading) {
      language(lan).then((res) => {
        setTextData(res["secondLang"] ? res["secondLang"] : res["default"]);
        // console.log(res);
      });
      setLoading(false);
    }
  }, [isLoading, lan]);

  // const handleNetbanking = (e) => {
  //     const bank_code = e.target.getAttribute('bank_code');
  //     const url = `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?payment_id=15&plan_details_id=1&pg=NB&bankcode=${bank_code}&type=charge&${urlparams}`;
  //     window.location.href = url;
  // }

  //console.log(nbPayments);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="wrapper">
        <div className="main-container">
          <div className="pay-head">
            <div className="pay-back" onClick={goToPreviousPath}>
              <img
                src={
                  process.env.REACT_APP_ASSETS_URL +
                  "/mobile/images/back-arrow.svg"
                }
                onClick={goToPreviousPath}
                alt="arrow"
              />
            </div>
            <span>{textData?.pay_netbanking_label}</span>
          </div>
          <div className="net-bankingbox">
            <div className="net-bankingbox-inner">
              {/* <div className="pay-mode-box">
                                <p className="p-t1">{textData?.secondLang?.pay_search_bank_label ? textData?.secondLang?.pay_search_bank_label : textData?.default?.pay_search_bank_label}</p>
                                <div className="bank-searchbox flex1">
                                    <div className="bank-search">
                                      
                                        <select
                                            className="bank-searchinput"
                                            value={bankSelect}
                                            onChange={e => handleForm(e)}
                                        >
                                            {
                                                netBankArray && netBankArray.length > 0 &&

                                                netBankArray.map((data, i) => (
                                                    <>
                                                        <option className={"moreBankList"} key={i} value={data?.bankcode}>{data?.bankName}</option>
                                                    </>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="bank-searchbtnbox">
                                        <button className="button-blue" bank_code={bankSelect} onClick={e => handleNetbanking(e)}>{textData?.secondLang?.pay_go_button ? textData?.secondLang?.pay_go_button : textData?.default?.pay_go_button}</button>
                                    </div>
                                </div>
                            </div>
                            */}

              <div className="pay-mode-box more_netbanking_list">
                {/*<p className="p-t1">{textData?.secondLang?.pay_popular_banks_text ? textData?.secondLang?.pay_popular_banks_text : textData?.default?.pay_popular_banks_text}</p>*/}

                {/*
                                    nbPayments && nbPayments.length > 0 &&

                                    nbPayments.map((data, i) => (
                                        <div className="bankrow" key={i} bank_code={data.bank_code} onClick={e => handleNetbanking(e, data)}>
                                            <div className="bank-iconbox">
                                                <div>
                                                    <img bank_code={data.bank_code} src={data.payment_logo} alt={data.bank_display_name} className="bank-icon" />
                                                </div>
                                            </div>

                                            <div className="bank-namebox" bank_code={data.bank_code}>
                                                <div className="pointer" bank_code={data.bank_code}>{data.bank_display_name}</div>
                                            </div>
                                        </div>
                                    ))
                                    */}

                {netBankArray &&
                  netBankArray.length > 0 &&
                  netBankArray.map((data, i) => (
                    <div
                      className="bankrow"
                      key={i}
                      bank_code={data?.bankcode}
                      onClick={(e) => handleNetbanking(e, data)}
                    >
                      <div className="bank-iconbox">
                        <div>
                          <img
                            bank_code={data?.bankcode}
                            src={data?.bankLogo}
                            alt={data?.bankName}
                            className="bank-icon"
                          />
                        </div>
                      </div>

                      <div className="bank-namebox" bank_code={data?.bankcode}>
                        <div className="pointer" bank_code={data?.bankcode}>
                          {data?.bankName}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// let arr = Array('Airtel Payments Bank|AIRNB', 'Andhra Bank|ADBB', 'Axis NB|AXIB', 'Bank Of India|BOIB', 'Bank Of Maharashtra|BOMB', 'Bharat Co-Op Bank|BHNB', 'Canara Bank|CABB', 'Catholic Syrian Bank|CSBN', 'Central Bank of India|CBIB', 'Citibank Netbanking|CITNB', 'City Union Bank|CUBB', 'Corporation Bank|CRBP', 'Cosmos Bank|CSMSNB', 'DCB Bank - Corporate Netbanking|DCBCORP', 'Dena Bank|DENN', 'Deutsche Bank|DSHB', 'Development Credit Bank|DCBB', 'Dhanlaxmi Bank|DLSB', 'HDFC Bank|HDFB', 'ICICI|ICIB', 'IDFC|IDFCNB', 'Indian Bank|INDB', 'Indian Overseas Bank|INOB', 'IndusInd Bank|INIB', 'Industrial Development Bank of India|IDBB', 'Jammu and Kashmir Bank|JAKB', 'Janata Sahakari Bank Pune|JSBNB', 'Karnataka Bank|KRKB', 'Karur Vysya - Corporate Netbanking|KRVBC', 'Kotak Mahindra Bank|162B', 'Lakshmi Vilas Bank - Corporate Netbanking|LVCB', 'Lakshmi Vilas Bank - Retail Netbanking|LVRB', 'Nainital Bank|TBON', 'Oriental Bank of commerce|OBCB', 'Punjab And Maharashtra Co-operative Bank Limited|PMNB', 'Punjab And Sind Bank|PSBNB', 'Punjab National Bank - Corporate Banking|CPNB', 'Punjab National Bank - Retail Banking|PNBB', 'Saraswat bank|SRSWT', 'SBI NB|SBIB', 'Shamrao Vithal Co-operative Bank Ltd|SVCNB', 'Syndicate Bank|SYNDB', 'Tamilnad Mercantile Bank|TMBB', 'The Federal Bank|FEDB', 'The Karur Vysya Bank|KRVB', 'The South Indian Bank|SOIB', 'UCO Bank|UCOB', 'Union Bank - Corporate Netbanking|UBIBC', 'Union Bank Of India|UBIB', 'United Bank of India|UNIB', 'Vijaya Bank|VJYB', 'Yes Bank|YESB');

// let arrr = {
//     'AIRNB': ""
// }
